$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                scrollTo(target);
                return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 120; // Distance from Browserbottom where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroVideo
    // *
    // *
    var $heroVideo = $(".js-herovideo");

    $(".js-herovideo-soundbutton").click(function () {
        var $video = $(this).toggleClass('active').parents(".js-herovideo-wrapper").find("video");
        $video.prop("muted", !$video.prop("muted"));
    });

    $(".js-herovideo-playbutton").click(function () {
        var $video = $(this).parents(".js-herovideo-wrapper").find("video"),
            video = $video[0];

        video.pause();
        video.currentTime = 0;
        video.load();
    });

    if ($heroVideo.length) {
        if ($(window).width() < 600) {
            var src = $heroVideo.data("src-mobile");
            $heroVideo.attr("src", src);
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    var $team = $(".js-team");

    if ($team.length) {
        $team.each(function () {

            var $teamWrapper = $(this).parents(".js-team-wrapper");

            $(this).slick({
                infinite: false,
                fade: false,
                dots: false,
                arrows: true,
                autoplay: false,
                speed: 900,
                waitForAnimate: true,
                slidesToScroll: 1,
                variableWidth: true,
                prevArrow: $teamWrapper.find(".js-team-nav-prev"),
                nextArrow: $teamWrapper.find(".js-team-nav-next"),
                draggable: true,
                rows: 0,
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * loader
    // *
    // *
    if ($(".js-loader").length) {
        var introVideo = sessionStorage.introVideo;

        if (!introVideo) {
            $(".js-loader").show();
            $(".js-loader").delay(2500).fadeOut();
        }

        sessionStorage.introVideo = true;
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * footerToggleContent
    // *
    // *
    $(".js-footer-togglecontent-button").click(function () {
        $(this).parents(".js-footer-togglecontent-wrapper").find(".js-footer-togglecontent").slideToggle();
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * mediaWall show more button
    // *
    // *
    $(".js-media-wall-button").click(function () {
        $(this).hide().parents(".js-media-wall-wrapper").find(".js-media-wall").addClass("active");
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * video.js
    // *
    // *

    if ($(".video-js").length > 0) {
        var player = videojs(document.querySelector('.video-js'), {
            errorDisplay: false,
            fill: true,
            fluid: true,
            controlBar: {
                pictureInPictureToggle: false
            }
        });
    }

});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 80) {
        $(".js-header").addClass("sticky");
    } else {
        $(".js-header").removeClass("sticky");
    }
});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * parallax
// *
// *
$(window).on("load scroll", function () {
    $("[data-parallax-speed]").each(function () {
        var scrollDistance = $(window).scrollTop(),
            elementParallaxOffset = parseInt($(this).css('transform').split(',')[5] ?? 0),
            elementDistance = Math.round($(this).offset().top - elementParallaxOffset),
            windowHeight = $(window).height(),
            parallaxValue = Math.round(((scrollDistance - elementDistance) + (windowHeight / 3))),
            parallaxValue = (parallaxValue * -1),
            parallaxSpeed = $(this).data("parallax-speed"),
            parallaxValue = parallaxValue * parallaxSpeed;

        $(this).css("transform", 'translateY(' + parallaxValue + 'px)');
        $(this).data("parallax-original", parallaxValue);

        // set follower the same position as the leader
        var id = $(this).data("parallax-leader");
        $('[data-parallax-follower="' + id + '"]').css("transform", 'translateY(' + parallaxValue + 'px)');
    });
});

// * * * * * * * * * * * * * * * * * * * * * * * * *
// * scrollSequence
// *
// *
var lastTranslateCount = 0;

$(window).on("load scroll", function () {
    if ($(".js-scrollsequence").length) {
        var $sequence = $(".js-scrollsequence"),
            sequenceStart = $sequence.offset().top,
            sequenceHeight = $sequence.height(),
            sequenceEnd = ((sequenceStart + sequenceHeight) - $(window).height()),
            scrollPosition = $(window).scrollTop(),
            imageHeight = $sequence.find("img:first").height(),
            imageNaturalWidth = 1920,
            imageNaturalHeight = 1080,
            imageCount = $sequence.find("img").length;

        // set height of canvas
        $(".js-scrollsequence-canvas").css("height", imageHeight);
        $(".js-scrollsequence-canvaswrapper").css("height", imageHeight);

        if (scrollPosition >= sequenceStart && scrollPosition <= sequenceEnd) {
            var scrollDistanceInView = (scrollPosition - sequenceStart),
                step = (sequenceHeight / imageCount),
                translateCount = Math.floor((scrollDistanceInView / step) + 1),
                translateDistance = (translateCount * imageHeight);

            // translate stage
            if (lastTranslateCount != translateCount) {
                //$imageStage.css("transform", 'translateY(-' + translateDistance + 'px)');
                lastTranslateCount = translateCount;

                const canvas = document.getElementById("canvas"),
                    ctx = canvas.getContext("2d"),
                    image = document.getElementById("source-" + translateCount);

                canvas.width = imageNaturalWidth;
                canvas.height = imageNaturalHeight;

                ctx.drawImage(image, 0, 0);
            }
        }
    }
});

